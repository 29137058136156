<template>
  <v-container>
    <v-row>
      <v-col
        cols="6"
      >
        <img
          :src="require('../../assets/lamiapratica_nero.png')"
          height="30px"
          alt=""
        >
      </v-col>
      <v-col
        cols="6"
      >
        <v-card
          class="mx-auto pa-4 pa-sm-6 pa-md-16 my-4"
          elevation="6"
          style="max-width: 600px;"
        >
          <v-row>
            <v-col>
              <h2 class="mb-2">
                Conferma Invito
              </h2>
            </v-col>
          </v-row>
          <v-form
            ref="form"
            class=""
            @submit.prevent="confirmInvite"
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.name"
                  :rules="[formRules.required]"
                  label="Nome *"
                  required
                  dense
                  outlined
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.surname"
                  :rules="[formRules.required]"
                  label="Cognome *"
                  required
                  dense
                  outlined
                />
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col>
                <v-text-field
                  v-model="user.email"
                  label="Email"
                  type="email"
                  required
                  :rules="[formRules.required,formRules.emailRules]"
                  dense
                  outlined
                  disabled
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.password"
                  :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="show1 ? 'text' : 'password'"
                  label="Password"
                  required
                  dense
                  outlined
                  :rules="[formRules.required,formRules.pswRule]"
                  @click:append="show1 = !show1"
                  @keyup.enter="register1"
                  @input="checkPassword"
                  @focus="showPswRules()"
                />
                <ul v-if="pswRulesBox">
                  <li :class="{ is_valid: contains_eight_characters }">
                    Almeno 8 caratteri
                  </li>
                  <li :class="{ is_valid: contains_number }">
                    Almeno un numero
                  </li>
                  <li :class="{ is_valid: contains_uppercase }">
                    Almeno una maiuscola
                  </li>
                  <li :class="{ is_valid: contains_special_character }">
                    Almeno un carattere speciale
                  </li>
                </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="user.password2"
                  :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="show2 ? 'text' : 'password'"
                  label="Conferma Password"
                  required
                  dense
                  outlined
                  :rules="[formRules.required,formRules.pswMatch(user.password2,user.password)]"
                  @click:append="show2 = !show2"
                  @keyup.enter="register1"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-checkbox
                  v-model="user.privacy_setting"
                  dense
                  :rules="[formRules.required]"
                  label="Acconsento al Trattamento dei Dati Personali"
                />
                <v-checkbox
                  v-model="user.marketing_setting"
                  dense
                  label="Acconsento al Marketing"
                />
              </v-col>
            </v-row>
            <v-row class="my-1">
              <v-col>
                <v-btn
                  :loading="loading"
                  block
                  class="primary darken-1 white--text"
                  @click.prevent="confirmInvite"
                >
                  Prosegui
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-if="progress > 0"
              class="mt-3"
            >
              <v-col class="text-center">
                <v-progress-linear
                  v-model="progress"
                  height="20"
                >
                  <strong>{{ Math.ceil(progress) }}%</strong>
                </v-progress-linear>
              </v-col>
            </v-row>
            <v-alert
              v-if="error"
              dense
              outlined
              color="error"
              class="my-5"
              dismissible
            >
              {{ error }}
            </v-alert>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data: () => ({
    loading: false,
    show1: false,
    show2: false,
    pswRulesBox: false,
    errorPasswordMatch: false,
    password_length: 0,
    contains_eight_characters: false,
    contains_number: false,
    contains_uppercase: false,
    contains_special_character: false,
    valid_password: false,
    user: {
      email: '',
      password: '',
      password2: '',
      name: '',
      surname: '',
      privacy: '',
      marketing: '',
      device_name: 'general_device',
    },
    error: null,
    invite: {},
  }),
  computed: {
    ...mapGetters(['formRules', 'progress']),
  },
  mounted() {
    this.getInvite()
  },
  methods: {
    showPswRules() {
      this.pswRulesBox = true
    },
    checkPassword() {
      this.password_length = this.user.password.length
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/

      this.contains_eight_characters = this.password_length > 8

      this.contains_number = /\d/.test(this.user.password)
      this.contains_uppercase = /[A-Z]/.test(this.user.password)
      this.contains_special_character = format.test(this.user.password)

      this.valid_password = this.contains_eight_characters === true
                && this.contains_special_character === true
                && this.contains_uppercase === true
                && this.contains_number === true
    },
    register() {
      this.error = null
      if (this.$refs.form.validate()) {
        this.$store.dispatch('register', this.user).then(() => {
          this.$router.push({ name: 'Home' })
        }).catch(err => {
          if (err.response) {
            this.error = 'register error'
          }
        })
      }
    },
    async getInvite() {
      if (this.$route.params.tk) {
        try {
          const response = await this.$api.readInvite(this.$route.params.tk)
          this.invite = response.data
          this.user.email = this.invite.target_user_email
        } catch (e) {
          console.log(e)
        }
      }
    },
    async confirmInvite() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.$api.registerInvite(this.$route.params.tk, this.user)
          await this.$store.dispatch('set_alert', { msg: 'Profilo creato', type: 'success' })
          await this.$store.dispatch('login', this.user).then(async () => {
            await this.$store.dispatch('reload_all_data')

            setTimeout(async () => {
              await this.$router.push({ name: 'dashboard' })
            }, 500)
          }).catch(err => {
            if (err.response) {
              this.error = 'login error'
            }
          })
        } catch (e) {
          this.loading = false
          await this.$store.dispatch('set_alert', { msg: 'error', type: 'error' })
        }
      }
    },
  },
}
</script>
<style>
.is_valid { color: #8BC34A; }
.is_valid:before { width: 100%; }
.no-uppercase {
    text-transform: unset !important;
}
</style>
